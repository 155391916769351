<template>
  <div>
    <!-- 首页导航栏 -->
    <div id="app">
      <Ztl />
    </div>

    <!-- 产品导航栏 -->
    <div id="app">
      <Cztl />
    </div>
    <!-- 产品功能块 -->
    <div class="content gongnengkuai">
      <div class="modle" data-top="20" v-for="funcBlock in funcBlocks" :key="funcBlock.id">
        <div class="page">
          <div class="list-One">
            <a :href="funcBlock.href">
              <ul class="list-One-ul">
                <li class="img">
                  <img :src="funcBlock.imgSrc" :alt="funcBlock.title" :title="funcBlock.title">
                </li>
                <li class="list-One-li">
                  <p class="list-One-p">{{ funcBlock.title }}</p>
                </li>
              </ul>
            </a>
          </div>
        </div>
      </div>
    </div>


    <!-- 底部栏 -->
    <div id="app">
      <Footer />
    </div>
  </div>
</template>

<script>
import Ztl from "@/components/Ztl.vue";
import Footer from "@/components/Footer.vue";
import Cztl from "@/components/Cztl.vue";

export default {
  name: "Uwbdingweixitong",
  components: {Footer, Ztl,Cztl},
  data() {
    return {
      funcBlocks: [
        { title: '1', href: '#', imgSrc: require('../assets/uwbxitong/dwxt1.jpg')},
        { title: '2', href: '#', imgSrc: require('../assets/uwbxitong/dwxt2.jpg')},
        { title: '3', href: '#', imgSrc: require('../assets/uwbxitong/dwxt3.jpg')},
        { title: '4', href: '#', imgSrc: require('../assets/uwbxitong/dwxt4.jpg')},
        { title: '5', href: '#', imgSrc: require('../assets/uwbxitong/dwxt5.jpg')},
        { title: '6', href: '#', imgSrc: require('../assets/uwbxitong/dwxt6.jpg')},
        {title: '7', href: '#', imgSrc: require('../assets/uwbxitong/dwxt7.jpg')},
        {title: '8', href: '#', imgSrc: require('../assets/uwbxitong/dwxt8.jpg')},
        {title: '9', href: '#', imgSrc: require('../assets/uwbxitong/dwxt9.jpg')},
        {title: '10', href: '#', imgSrc: require('../assets/uwbxitong/dwxt10.jpg')},
      ],
    };
  },
};
</script>

<style scoped>

.fenleiliebiao .one li{
  border: 1px solid #e0e0e0; /* 边框颜色和宽度 */
  border-radius: 8px; /* 圆角 */
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* 阴影效果 */
  overflow: hidden; /* 确保圆角和阴影不会被内部内容破坏 */
  transition: box-shadow 0.3s ease; /* 平滑的阴影过渡 */
}
.fenleiliebiao .one li {
  margin-bottom: 20px;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center;     /* 垂直居中 */
}

.content.gongnengkuai {
  display: flex;
  flex-wrap: wrap; /* 允许模块换行 */
  justify-content: center; /* 横向居中 */
  align-items: flex-start; /* 纵向对齐起点 */
  max-width: 1000px; /* 设置最大宽度，根据需要调整 */
  margin: 0 auto; /* 自动居中 */
  padding: 0px; /* 内边距保持不变 */
}
/* 为 .list-One-ul 添加样式 */
.list-One-ul {
  display: flex;
  flex-direction: column; /* 使元素垂直堆叠 */
  align-items: center; /* 水平居中 */
  transition: transform 0.3s ease; /* 添加过渡效果，持续时间为0.3秒，使用平滑的缓动函数 */
}
.modle {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: box-shadow 0.3s ease;
  margin: 0px; /* 添加四周的间距，确保模块间有空隙 */
}
.list-One-ul:hover {
  transform: translateY(-10px); /* 鼠标悬停时图片向上移动 */
}
.list-One-ul .img img {
  max-width: 100%; /* 图片的最大宽度为容器宽度的100%，确保图片不会超出容器 */
  max-height: 300px; /* 图片的最大高度，可以根据需要调整 */
  width: auto; /* 自动调整宽度，保持原始宽高比 */
  height: auto; /* 自动调整高度，保持原始宽高比 */
}

</style>
